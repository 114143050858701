import React from "react"
import styled from "@emotion/styled"

import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import Header from "../views/Careers/Header"
import Intro from "../views/Careers/Intro"
import OurValues from "../views/Careers/OurValues"
import TheBenefits from "../views/Careers/TheBenefits"
import BottomImages from "../views/Careers/BottomImages"

import config from "../../config.json"

const pageName = "careers"

const PageContainer = styled.div`
  overflow: hidden;
  min-height: 100%;

  > header {
    margin-top: 0;
  }
`

const Page: React.FC = () => {
  return (
    <PageContainer>
      <Navbar />

      <main id="main-content">
        <Header />
        <Intro />
        <OurValues />
        <TheBenefits />
        <BottomImages />
      </main>

      <Footer />
    </PageContainer>
  )
}

export const Head: React.FC = () => (
  <WrappedHead
    metaTitle="Careers | Habito"
    metaDescription="We're the Treating Customers Fairly Champion of 2023 as voted for in the British Bank Awards. We're here to make mortgages easier - no paperwork, no stress and no fees."
    canonicalUrl="https://www.habito.com/careers"
    noIndex={false}
    pageName={pageName}
    intercom={false}
    config={config}
  />
)

const CareersPage: React.FC = () => (
  <WrappedBase>
    <Page />
  </WrappedBase>
)

export default CareersPage
